@import "./variables.css";

html,
body {
  padding: 0;
  margin: 0;
  scrollbar-width: none;
  /* Firefox */
  overflow-x: unset !important;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  background-color: #F5F5F5 !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

body {
  overscroll-behavior: none;
}

#__next {
  overflow-x: unset !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none !important;
}

div[data-framer-component-type='Text'] span {
  font-size: var(--framer-font-size) !important;
  line-height: var(--framer-line-height) !important;
}

.stickyStyle {
  position: -webkit-sticky;
  position: sticky;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 20000;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradientOverlay {
  background-color: red;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(245, 245, 245, 1) 90%);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  font-weight: 700;
  margin: 0;
}

ul li:before {
  content: "" !important;
  list-style: none !important;
  padding: 0;
  margin: 0 !important;
}

.ais-Hits-list {
  margin: 0 !important;
}

.ais-SearchBox-input {
  border-radius: 12px;
  width: 100%;
  border: none;
  background-color: var(--color-primary-10);
  padding: 12px 20px;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}

@keyframes shakeAnimation {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  animation: shakeAnimation 0.5s;

}



@keyframes glowingOrb {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(142, 81, 200, 0.4), 0 0 20px rgba(142, 81, 200, 0.2), 0 0 30px rgba(142, 81, 200, 0.1);
  }

  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(142, 81, 200, 0.6), 0 0 40px rgba(142, 81, 200, 0.4), 0 0 60px rgba(142, 81, 200, 0.2);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(142, 81, 200, 0.4), 0 0 20px rgba(142, 81, 200, 0.2), 0 0 30px rgba(142, 81, 200, 0.1);
  }
}